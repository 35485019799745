import { styled } from '@linaria/react';

import { RMLink } from '@/components/RMLink/RMLink';

export const Content = styled.div`
  position: relative;
  display: flex;
  gap: var(--spacing-4xl);
  height: 100%;

  body[data-tablet='true'] & {
    flex-direction: column;
    gap: var(--spacing-2xl);
  }
`;

export const LeftPanel = styled.div`
  width: 40%;

  body[data-tablet='true'] & {
    width: 27rem;
  }
`;

export const RightPanel = styled.div`
  width: 60%;

  body[data-tablet='true'] & {
    width: 32.5rem;
    flex-grow: 1;
  }
`;

export const BookLoadingContainer = styled.div`
  border: 1px solid var(--border-strong);
  background-color: var(--surface-dim);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-md);
`;

export const AnimationContainer = styled.div`
  width: 5rem;
  height: 5rem;
  position: relative;
`;

export const BookContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacing-sm);
`;

export const BookViewer = styled.object`
  width: 100%;
  height: 100%;
`;

export const DownloadButton = styled.a`
  display: flex;
  gap: var(--spacing-xs);
  cursor: pointer;
  margin-left: auto;

  :visited {
    color: var(--primary);
  }
`;

export const ListItem = styled.div`
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
`;

export const ListDot = styled.div`
  width: 8px;
  height: 8px;
  flex: 0 0 8px;
  border-radius: 100%;
  background-color: var(--on-surface-primary);
`;

export const MessageContainer = styled.div`
  border-width: 2px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  border-radius: var(--radius-round);
  background-color: var(--surface-bright);

  &[data-variant='error'] {
    border-color: var(--danger);
  }

  &[data-variant='info'] {
    border-color: var(--color-aqua-70);
  }

  &[data-variant='warning'] {
    border-color: var(--caution);
    flex-direction: row;
  }
`;

export const Link = styled.a`
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;

  :visited {
    color: var(--primary);
  }
`;

export const PickerWrapper = styled.div`
  min-height: 5rem;
`;

export const OrderHistoryButton = styled(RMLink)`
  position: absolute;
  top: calc(var(--spacing-xl) * -1);
  right: 0;

  display: flex;
  gap: var(--spacing-xs);
  font-weight: 700;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-lg);
`;
