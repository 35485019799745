import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseAssetAlternativeType } from '@remento/types/alternative';
import { AssetType } from '@remento/types/asset';
import { BookColor } from '@remento/types/book';
import { EntityType } from '@remento/types/entity';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMPage } from '@/components/RMPage';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useBooleanQueryParam } from '@/hooks/useQueryParam';
import { logger } from '@/logger';
import { createBookCoverForm } from '@/modules/book-builder/book-cover.form';
import { useBookOrderContext } from '@/modules/book-builder/book-order.context';
import { BookCoverFormView } from '@/modules/book-builder/components/BookCoverFormView/BookCoverFormView';
import { BookCoverPreviewFormContainer } from '@/modules/book-builder/containers/BookCoverPreview.container';
import {
  BookOrderHeaderContainer,
  BookOrderHeaderStep,
} from '@/modules/book-builder/containers/BookOrderHeader.container';
import { BookOrderHistoryDialogContainer } from '@/modules/book-builder/containers/BookOrderHistoryDialog.container';
import { submitForm, useIsFormValid } from '@/modules/form/form';
import { getInputInteraction, setInputValues } from '@/modules/form/input';
import { MobilePageHeaderContainer } from '@/modules/navbar/containers/MobilePageHeaderContainer';
import { getBookOrderStoriesPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useAssetImageUrl } from '@/services/api/asset';
import { useBookQuery, useProjectBookOrdersQuery } from '@/services/api/book';
import { EntityMutation } from '@/services/api/cache';
import { useProjectStoriesQuery } from '@/services/api/story';
import { captureException } from '@/utils/captureException';

import { Content, LeftPanel, OrderHistoryButton, RightPanel } from './BookOrderCoverDesignPage.styles';

export function BookOrderCoverDesignPage() {
  // Route params
  const params = useParams();
  const projectId = params.projectId ?? '';
  const bookId = params.bookId ?? '';
  const [openOrderHistory, setOpenOrderHistory] = useBooleanQueryParam('open-order-history');

  // Services
  const { entityCacheManagerService, bookService, assetService } = useServices();
  const navigate = useNavigate();
  const isMobile = useIsMobileViewport();

  // Queries
  const bookOrdersQuery = useProjectBookOrdersQuery(projectId);
  const bookQuery = useBookQuery(bookId);
  const coverUrl = useAssetImageUrl(bookQuery.data?.coverAssetId ?? null, BaseAssetAlternativeType.ORIGINAL);
  // Pre-fetch all stories to decrease the loading time in the next step
  useProjectStoriesQuery(projectId);

  // State
  const { quantityForm } = useBookOrderContext();
  const form = useMemo(() => createBookCoverForm(), []);
  const isFormValid = useIsFormValid(form);
  const [changesSavedDialogOpen, setChangesSavedDialogOpen] = useState(false);
  const [orderHistoryDialogOpen, setOrderHistoryDialogOpen] = useState(false);

  const handleNext = useCallback(async () => {
    const currentBook = bookQuery.data;
    if (!currentBook) {
      logger.warn('BOOK_NOT_LOADED');
      return;
    }

    try {
      await submitForm(form, async (data) => {
        const mutations: EntityMutation[] = [];

        const colorInputInteraction = getInputInteraction(form, 'color');
        if (!currentBook.color || colorInputInteraction.isDirty) {
          mutations.push(bookService.createSetBookColorMutation(currentBook, data.color));
        }

        const titleInputInteraction = getInputInteraction(form, 'title');
        if (titleInputInteraction.isDirty) {
          mutations.push(bookService.createSetBookTitleMutation(currentBook, data.title));
        }

        const subtitleInputInteraction = getInputInteraction(form, 'subtitle');
        if (subtitleInputInteraction.isDirty) {
          mutations.push(bookService.createSetBookSubtitleMutation(currentBook, data.subtitle));
        }

        const photoInputInteraction = getInputInteraction(form, 'photoHandle');
        if (photoInputInteraction.isDirty) {
          const newAsset = data.photoHandle
            ? await assetService.createFilestackAsset({
                entity: { id: currentBook.id, type: EntityType.BOOK_TEMPLATE },
                type: AssetType.IMAGE,
                handle: data.photoHandle,
              })
            : null;
          mutations.push(bookService.createSetBookCoverPhotoMutation(currentBook, newAsset?.id ?? null));
        }

        if (mutations.length > 0) {
          await entityCacheManagerService.mutate(mutations);
        }

        if (isMobile) {
          setChangesSavedDialogOpen(true);
        } else {
          navigate(getBookOrderStoriesPath(projectId, bookId));
        }
      });
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [
    assetService,
    bookId,
    bookQuery.data,
    bookService,
    entityCacheManagerService,
    form,
    isMobile,
    navigate,
    projectId,
  ]);

  // Set initial form state
  useEffect(() => {
    if (bookQuery.data == null || (bookQuery.data.coverAssetId != null && coverUrl == null)) {
      return;
    }

    setInputValues(form, {
      color: bookQuery.data?.color ?? BookColor.FOREST,
      title: bookQuery.data?.title,
      subtitle: bookQuery.data?.subtitle,
      photoHandle: null,
      photoUrl: bookQuery.data.coverAssetId && coverUrl ? coverUrl : null,
    });
  }, [bookQuery.data, coverUrl, form]);

  useEffect(() => {
    if (openOrderHistory != null) {
      setOpenOrderHistory(null);

      if (openOrderHistory) {
        setOrderHistoryDialogOpen(true);
      }
    }
  }, [openOrderHistory, setOpenOrderHistory]);

  return (
    <RMPage.Root>
      <MobilePageHeaderContainer />
      <BookOrderHeaderContainer
        projectId={projectId}
        bookId={bookId}
        step={BookOrderHeaderStep.DesignCover}
        quantityForm={quantityForm}
      />
      <RMPage.Content>
        <Content>
          <LeftPanel>
            <RMText type="serif" size="l" color="on-surface-primary">
              Customize your book cover
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <RMText type="sans" size="s" color="on-surface-primary">
              Create a cover that's as unique as the stories you've gathered. Pick a color, add a special photo, and
              give it a title that resonates.
            </RMText>
            <RMSpacer spacing="2xl" direction="column" />

            <BookCoverFormView form={form} />

            <RMSpacer spacing="2xl" direction="column" />
            <RMButton
              style={{ minWidth: '6.25rem' }}
              background="primary"
              autoLoading
              disabled={isFormValid === false}
              onClick={handleNext}
            >
              Next
            </RMButton>
          </LeftPanel>
          <RightPanel>
            {(bookOrdersQuery.data?.length ?? 0) > 0 && (
              <OrderHistoryButton onClick={() => setOrderHistoryDialogOpen(true)}>
                <FontAwesomeIcon icon={faList} />
                View past orders
              </OrderHistoryButton>
            )}
            {!isMobile && (
              <RMText type="serif" size="l" color="on-surface-primary">
                Preview
              </RMText>
            )}
            <RMSpacer spacing="md" direction="column" />
            <BookCoverPreviewFormContainer coverForm={form} />
          </RightPanel>
        </Content>

        <RMConfirmationModal
          title="Changes saved!"
          message="You can continue placing your book order on a laptop or desktop computer."
          open={changesSavedDialogOpen}
          onClose={() => setChangesSavedDialogOpen(false)}
          onConfirm={() => setChangesSavedDialogOpen(false)}
          cancelLabel={null}
        />

        {orderHistoryDialogOpen && (
          <BookOrderHistoryDialogContainer projectId={projectId} onClose={() => setOrderHistoryDialogOpen(false)} />
        )}
      </RMPage.Content>
    </RMPage.Root>
  );
}
