import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ShippingAddress, ShippingAddressValidationResult } from '@remento/types/shipping';

import { RMPage } from '@/components/RMPage';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { useBookOrderContext } from '@/modules/book-builder/book-order.context';
import { BookShippingFormView } from '@/modules/book-builder/components/BookShippingFormView/BookShippingFormView';
import { BookReorderCheckoutReviewContainer } from '@/modules/book-builder/containers/BookReorderCheckoutReview.container';
import {
  BookReorderHeaderContainer,
  BookReorderHeaderStep,
} from '@/modules/book-builder/containers/BookReorderHeader.container';
import { BookReorderSummaryContainer } from '@/modules/book-builder/containers/BookReorderSummary.container';
import { ShippingAddressValidationDialogContainer } from '@/modules/book-builder/containers/ShippingAddressValidationDialog.container';
import { shouldShowShippingValidationDialog } from '@/modules/book-builder/utils/shipping.utils.js';
import { getFormValue, isFormValid, submitForm, useIsFormValid } from '@/modules/form/form';
import { setInputValues } from '@/modules/form/input';
import { getBookReorderQuantityPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';
import { getMarketingCookies } from '@/utils/marketing-cookies';

import { Button, ButtonsWrapper, Content, LeftPanel, RightPanel } from './BookReorderFinalizePage.styles.js';

interface ShippingAddressErrorState {
  input: ShippingAddress;
  validationResult: ShippingAddressValidationResult;
}

export function BookReorderFinalizePage() {
  // Route params
  const params = useParams();
  const projectId = params.projectId ?? '';
  const bookOrderId = params.bookOrderId ?? '';

  // Services
  const { bookService } = useServices();
  const navigate = useNavigate();

  // State
  const { quantityForm, shippingForm } = useBookOrderContext();
  const isShippingFormValid = useIsFormValid(shippingForm);

  const [step, setStep] = useState<'shipping' | 'review'>(() => {
    const quantity = getFormValue(quantityForm);
    if (quantity == null) {
      // The page will be redirected to the previous step in this case.
      return 'shipping';
    }
    return quantity.quantity == 0 ? 'review' : 'shipping';
  });
  const [isReviewed, setIsReviewed] = useState(false);
  const [shippingAddressError, setShippingAddressError] = useState<ShippingAddressErrorState | null>(null);

  // Callbacks
  const handleEdiShipping = useCallback(() => {
    setStep('shipping');
  }, []);

  const handlePrevious = useCallback(() => {
    if (step === 'review') {
      const quantityFormValue = getFormValue(quantityForm);
      if (quantityFormValue?.quantity !== 0) {
        setStep('shipping');
        return;
      }
    }
    navigate(getBookReorderQuantityPath(projectId, bookOrderId));
  }, [bookOrderId, navigate, projectId, quantityForm, step]);

  const handleReview = useCallback(async () => {
    try {
      await submitForm(shippingForm, async (formValue) => {
        const shippingAddress: ShippingAddress = {
          recipientName: formValue.recipientName,
          line1: formValue.addressLine1,
          line2: formValue.addressLine2,
          city: formValue.city,
          state: formValue.state,
          country: formValue.country,
          postalCode: formValue.zipCode,
        };

        const validationResult = await bookService.validateAddress(shippingAddress);
        if (!shouldShowShippingValidationDialog(shippingAddress, validationResult)) {
          return setStep('review');
        }

        setShippingAddressError({
          input: shippingAddress,
          validationResult,
        });
      });
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [bookService, shippingForm]);

  const handlePay = useCallback(async () => {
    const quantityFormValue = getFormValue(quantityForm);
    const shippingFormValue = getFormValue(shippingForm);
    if (quantityFormValue == null) {
      logger.warn('QUANTITY_FORM_NOT_SUBMITTED');
      return;
    }
    if (quantityFormValue.quantity > 0 && shippingFormValue == null) {
      logger.warn('SHIPPING_FORM_NOT_SUBMITTED');
      return;
    }

    try {
      const checkoutResponse = await bookService.checkoutBookOrder({
        type: 'reorder',
        bookOrderId,
        quantity: quantityFormValue.quantity,
        credits: quantityFormValue.creditsApplied,
        ebook: quantityFormValue.ebook,
        shippingAddress: shippingFormValue,
        ...getMarketingCookies(),
      });
      window.open(checkoutResponse.paymentUrl, '_self');
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [bookOrderId, bookService, quantityForm, shippingForm]);

  // Shipping address error callbacks
  const handleSaveAddressErrorDialog = useCallback(
    (address: ShippingAddress) => {
      setInputValues(shippingForm, {
        addressLine1: address.line1,
        addressLine2: address.line2,
        city: address.city,
        state: address.state,
        zipCode: address.postalCode,
      });
      setShippingAddressError(null);
      setStep('review');
    },
    [shippingForm],
  );

  const handleCloseAddressErrorDialog = useCallback(() => {
    setShippingAddressError(null);
  }, []);

  // Redirect to the previous step if the form is not valid
  useEffect(() => {
    if (isFormValid(quantityForm) == false) {
      navigate(getBookReorderQuantityPath(projectId, bookOrderId));
    }
  }, [bookOrderId, navigate, projectId, quantityForm]);

  return (
    <RMPage.Root>
      <BookReorderHeaderContainer
        projectId={projectId}
        bookOrderId={bookOrderId}
        step={BookReorderHeaderStep.Checkout}
        quantityForm={quantityForm}
      />
      <RMPage.Content>
        <Content>
          <LeftPanel>
            <RMText type="serif" size="l" color="on-surface-primary">
              {step === 'review' ? 'Review and pay' : 'Enter your shipping information'}
            </RMText>

            <RMSpacer direction="column" spacing="2xl" />

            {step === 'shipping' && <BookShippingFormView form={shippingForm} />}
            {step === 'review' && (
              <BookReorderCheckoutReviewContainer
                projectId={projectId}
                bookOrderId={bookOrderId}
                quantityForm={quantityForm}
                shippingForm={shippingForm}
                reviewed={isReviewed}
                onReviewedChange={setIsReviewed}
                onEditShippingAddress={handleEdiShipping}
              />
            )}

            <RMSpacer direction="column" spacing="2xl" />

            <ButtonsWrapper>
              <Button background="neutral" autoLoading onClick={handlePrevious}>
                Previous
              </Button>

              {step === 'shipping' && (
                <Button
                  background="primary"
                  autoLoading
                  disabled={isShippingFormValid === false}
                  onClick={handleReview}
                >
                  Review
                </Button>
              )}
              {step === 'review' && (
                <Button background="primary" autoLoading disabled={isReviewed === false} onClick={handlePay}>
                  Continue to payment
                </Button>
              )}
            </ButtonsWrapper>
          </LeftPanel>
          <RightPanel>
            <BookReorderSummaryContainer
              projectId={projectId}
              bookOrderId={bookOrderId}
              quantityForm={quantityForm}
              shippingForm={shippingForm}
            />
          </RightPanel>
        </Content>

        {shippingAddressError != null && (
          <ShippingAddressValidationDialogContainer
            input={shippingAddressError.input}
            validationResult={shippingAddressError.validationResult}
            supportMessageSuffix="I have received the following error message as I attempted to ship my book."
            onSave={handleSaveAddressErrorDialog}
            onClose={handleCloseAddressErrorDialog}
          />
        )}
      </RMPage.Content>
    </RMPage.Root>
  );
}
