import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faList, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StoryStatus } from '@remento/types/story';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMPage } from '@/components/RMPage';
import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { logger } from '@/logger';
import { useBookOrderContext } from '@/modules/book-builder/book-order.context';
import {
  createBookStoriesStore,
  getIncludedBookStoriesIds,
  moveBookStory,
  setBookStoriesIds,
  useBookStoriesIds,
  useIncludedBookStoriesCount,
  useIncludedBookStoriesIds,
} from '@/modules/book-builder/book-stories.state';
import { BookStoryList } from '@/modules/book-builder/components/BookStoryList';
import {
  BookOrderHeaderContainer,
  BookOrderHeaderStep,
} from '@/modules/book-builder/containers/BookOrderHeader.container';
import { BookOrderHistoryDialogContainer } from '@/modules/book-builder/containers/BookOrderHistoryDialog.container';
import { BookStoryListItemContainer } from '@/modules/book-builder/containers/BookStoryList.container';
import { getBookOrderCoverDesignPath, getBookOrderPreviewPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useBookQuery, useProjectBookOrdersQuery } from '@/services/api/book';
import { useFilterStoriesIds, useProjectStoriesQuery, useStoriesIssues } from '@/services/api/story';
import { captureException } from '@/utils/captureException';

import {
  ContentWrapper,
  ContinueDialogBody,
  FaqLink,
  IssuesMessageWrapper,
  LeftContent,
  LeftPanel,
  OrderHistoryButton,
  RightContent,
  RightPanel,
} from './BookOrderStoriesPage.styles';

export function BookOrderStoriesPage() {
  // Route params
  const params = useParams();
  const projectId = params.projectId ?? '';
  const bookId = params.bookId ?? '';

  // Services
  const { entityCacheManagerService, bookService } = useServices();
  const navigate = useNavigate();

  // Queries
  const bookQuery = useBookQuery(bookId);
  const storiesQuery = useProjectStoriesQuery(projectId);
  const reversedRecordedStoriesIds = useFilterStoriesIds(storiesQuery.data ?? null, StoryStatus.RECORDED);
  // We should show the oldest stories first.
  // THe backend will return the stories sorted from most recent to oldest.
  const recordedStoriesIds = useMemo(
    () => (reversedRecordedStoriesIds ? [...reversedRecordedStoriesIds].reverse() : null),
    [reversedRecordedStoriesIds],
  );
  const bookOrdersQuery = useProjectBookOrdersQuery(projectId);

  // State
  const { quantityForm } = useBookOrderContext();
  const bookStoriesStore = useMemo(() => createBookStoriesStore(), []);
  const bookStoriesIds = useBookStoriesIds(bookStoriesStore);
  const includedBookStoriesIds = useIncludedBookStoriesIds(bookStoriesStore);
  const includedBookStoriesCount = useIncludedBookStoriesCount(bookStoriesStore);

  // Issues
  const allIssues = useStoriesIssues(includedBookStoriesIds);
  const [showConfirmationOpen, setShowConfirmationOpen] = useState(false);
  const [confirmationAccepted, setConfirmationAccepted] = useState(false);
  const [showConfirmationError, setShowConfirmationError] = useState(false);

  // Order history state
  const [orderHistoryDialogOpen, setOrderHistoryDialogOpen] = useState(false);

  const handlePrevious = useCallback(() => {
    navigate(getBookOrderCoverDesignPath(projectId, bookId));
  }, [bookId, navigate, projectId]);

  const handleNext = useCallback(async () => {
    const book = bookQuery.data;
    if (!book) {
      logger.warn('BOOK_NOT_LOADED');
      return;
    }

    if (allIssues != null && allIssues.length > 0) {
      if (confirmationAccepted == false) {
        if (showConfirmationOpen == false) {
          setShowConfirmationOpen(true);
          return;
        }
        setShowConfirmationError(true);
        return;
      }
    }

    try {
      await entityCacheManagerService.mutate([
        bookService.createSetBookStoriesMutation(book, getIncludedBookStoriesIds(bookStoriesStore)),
      ]);
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }

    navigate(getBookOrderPreviewPath(projectId, bookId));
  }, [
    allIssues,
    bookId,
    bookQuery.data,
    bookService,
    bookStoriesStore,
    confirmationAccepted,
    entityCacheManagerService,
    navigate,
    projectId,
    showConfirmationOpen,
  ]);

  const handleSaveStories = useCallback(async () => {
    const book = bookQuery.data;
    if (!book) {
      logger.warn('BOOK_NOT_LOADED');
      return;
    }

    try {
      await entityCacheManagerService.mutate([
        bookService.createSetBookStoriesMutation(book, getIncludedBookStoriesIds(bookStoriesStore)),
      ]);
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [bookQuery.data, bookService, bookStoriesStore, entityCacheManagerService]);

  const handleMove = useCallback(
    (draggedStoryId: string, droppedStoryId: string) => {
      moveBookStory(bookStoriesStore, draggedStoryId, droppedStoryId);
      handleSaveStories();
    },
    [bookStoriesStore, handleSaveStories],
  );

  const handleCloseConfirmation = useCallback(() => {
    setShowConfirmationOpen(false);
    setConfirmationAccepted(false);
    setShowConfirmationError(false);
  }, []);

  // Update the store
  useEffect(() => {
    if (recordedStoriesIds === null || bookQuery.data == null) {
      return;
    }

    let sortedStories = recordedStoriesIds;
    if (bookQuery.data.storyIds?.length) {
      sortedStories = Array.from(sortedStories).sort((a, b) => {
        const aIndex = bookQuery.data.storyIds.indexOf(a);
        const bIndex = bookQuery.data.storyIds.indexOf(b);
        if (aIndex === -1 && bIndex === -1) {
          return 0;
        }
        if (aIndex !== -1 && bIndex === -1) {
          return -1;
        }
        if (aIndex === -1 && bIndex !== -1) {
          return 1;
        }
        return aIndex - bIndex;
      });
    }

    setBookStoriesIds(
      bookStoriesStore,
      sortedStories,
      bookQuery.data.storyIds?.length ? bookQuery.data.storyIds : recordedStoriesIds,
    );
  }, [bookQuery.data, bookStoriesStore, recordedStoriesIds]);

  return (
    <RMPage.Root>
      <BookOrderHeaderContainer
        projectId={projectId}
        bookId={bookId}
        step={BookOrderHeaderStep.ReviewStories}
        quantityForm={quantityForm}
      />
      <RMPage.Content>
        <ContentWrapper>
          <LeftContent>
            <LeftPanel>
              <RMText type="serif" size="l" color="on-surface-primary">
                Review and arrange your stories
              </RMText>
              <RMSpacer spacing="md" direction="column" />
              <RMText type="sans" size="s" color="on-surface-primary">
                Drag the stories up and down to change their order, or exclude any you don’t want in your book.
              </RMText>

              <RMSpacer spacing="2xl" direction="column" />

              <RMText type="sans" size="s" color="on-surface-primary" bold>
                {includedBookStoriesCount} out of {bookStoriesIds.length} stories included
              </RMText>

              {allIssues != null && allIssues.length > 0 && (
                <>
                  <RMSpacer spacing="md" direction="column" />
                  <IssuesMessageWrapper>
                    <FontAwesomeIcon icon={faTriangleExclamation} color="var(--caution)" />
                    <RMText type="sans" size="xs" color="caution">
                      We’ve identified {allIssues.length} potential issue{allIssues.length > 1 ? 's' : ''}. Please
                      review your stories and make adjustments if necessary. When you are happy with your stories,
                      select “Next”
                      <br />
                      <br />
                      See our{' '}
                      <FaqLink
                        href="https://help.remento.co/en/articles/8915122-how-does-the-remento-book-work"
                        target="_blank"
                      >
                        FAQ
                      </FaqLink>{' '}
                      for more information on how to resolve printing issues.
                    </RMText>
                  </IssuesMessageWrapper>
                </>
              )}

              <RMSpacer spacing="2xl" direction="column" />

              <RMStack direction="row" spacing="lg">
                <RMButton background="neutral" autoLoading onClick={handlePrevious}>
                  Previous
                </RMButton>
                <RMTooltip
                  side="top"
                  message={bookStoriesIds.length === 0 ? 'You haven’t collected any stories yet' : null}
                >
                  <div>
                    <RMButton
                      style={{ minWidth: '6.25rem' }}
                      background="primary"
                      autoLoading
                      disabled={bookStoriesIds.length === 0}
                      onClick={handleNext}
                    >
                      Next
                    </RMButton>
                  </div>
                </RMTooltip>
              </RMStack>
            </LeftPanel>
          </LeftContent>
          <RightContent>
            <RightPanel>
              {(bookOrdersQuery.data?.length ?? 0) > 0 && (
                <OrderHistoryButton onClick={() => setOrderHistoryDialogOpen(true)}>
                  <FontAwesomeIcon icon={faList} />
                  View past orders
                </OrderHistoryButton>
              )}

              <BookStoryList.Root storiesIds={bookStoriesIds} onMove={handleMove}>
                {bookStoriesIds.length === 0 && <BookStoryList.Empty />}
                {bookStoriesIds.map((storyId) => (
                  <BookStoryListItemContainer
                    key={storyId}
                    store={bookStoriesStore}
                    storyId={storyId}
                    onChangeStories={handleSaveStories}
                  />
                ))}
              </BookStoryList.Root>
            </RightPanel>
          </RightContent>
        </ContentWrapper>

        {/* Confirm issues dialog */}
        <RMDialog.Root open={showConfirmationOpen}>
          <RMDialog.Content>
            <RMDialog.Header
              title="Are you sure you’d like to continue?"
              message={`We’ve identified ${allIssues?.length} potential issue${
                (allIssues?.length ?? 0) > 1 ? 's' : ''
              }. Please review your stories and make adjustments if necessary. `}
              rightAdornment={<RMCloseButton onClick={handleCloseConfirmation} />}
            />
            <RMDialog.Body>
              <ContinueDialogBody>
                <RMPicker.Root>
                  <RMPicker.Item
                    label="I confirm that I would like to order my book without making further changes."
                    value="true"
                    bold="medium"
                    selected={confirmationAccepted}
                    error={showConfirmationError ? 'You must confirm to continue' : null}
                    onClick={() => {
                      setConfirmationAccepted((accepted) => {
                        setShowConfirmationError(false);
                        return !accepted;
                      });
                    }}
                  />
                </RMPicker.Root>
              </ContinueDialogBody>
            </RMDialog.Body>
            <RMDialog.Footer>
              <RMButton onClick={handleCloseConfirmation}>Cancel</RMButton>
              <RMButton background="primary" autoLoading onClick={handleNext}>
                Yes, continue
              </RMButton>
            </RMDialog.Footer>
          </RMDialog.Content>
        </RMDialog.Root>

        {orderHistoryDialogOpen && (
          <BookOrderHistoryDialogContainer projectId={projectId} onClose={() => setOrderHistoryDialogOpen(false)} />
        )}
      </RMPage.Content>
    </RMPage.Root>
  );
}
